import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"

import Layout from "../components/Layout/layout"
import Seo from "../components/Seo/seo"
import ImageTextBlock from "../components/ImageTextBlock/imageTextBlock"
import Heading from "../components/Heading/heading"
import { H1 } from "../styles/typography"
import { main } from "../styles/theme"

const HeadingWrapper = styled.div`
  margin: 46px 0 0;

  @media ${main.breakpoints.large} {
    margin: 196px 0 0;
  }
`

const OmOss = () => (
  <Layout>
    <Seo title="Om oss" />
    <HeadingWrapper>
      <H1 size="large">Om oss</H1>
    </HeadingWrapper>
    <StaticImage
      src="../images/detaljbild-tidigare-projekt.jpg"
      quality={95}
      layout="fullWidth"
      formats={["AUTO", "WEBP"]}
      alt="Om Smedjan och 4D gruppen"
      style={{ marginTop: `2.45rem` }}
    />
    <ImageTextBlock
      preHeading="Exploatören 4D Gruppen"
      text={[
        `Vi är ett bygg- och fastighetsutvecklingsbolag som utmanar traditionella arbetssätt och tankebanor. Vi söker innovativa lösningar, alltid med kvalitet och funktion. Alltid med ambitionen att skapa något som förändrar och förbättrar vårt sätt att leva på olika sätt. Livsmiljöer som förenklar livet, skapar förutsättningar att leva hållbart eller underlättar möten mellan människor.`,
        `Nyfikenheten styr oss, oavsett om det gäller nya relationer, kultur i olika former, arbetssätt eller affärer. Det finns alltid en undran om vad som finns runt nästa hörn.`,
        `4D Gruppen är ett etablerat namn när det kommer till kvadratsmarta centrala bostäder. Målet är att den som flyttar in i en lägenhet skapad av oss ska bo i ett unikt och välplanerat hem men också känna igen de goda materialvalen, omvårdad exklusivitet och hållbarhet.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      buttonTitle="Gå till 4dgruppen.se"
      externalLink="https://4dgruppen.se"
      image={
        <StaticImage
          src="../images/om-oss.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="4D Gruppen"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <Heading
      text="Samarbetspartners"
      level="h2withH1style"
      position="fullwidth"
      paddingBottom
    />
    <ImageTextBlock
      preHeading="Arkitekterna"
      text={[
        `Högberg Gillner Arkitektur grundades 2018 i Göteborg av Aron Wändahl Högberg och Benjamin Gillner. Kontoret kombinerar en stor bredd av professionell och akademisk erfarenhet.`,
        `Platsens specifika byggnadskultur är vår utgångspunkt i gestaltningsarbetet och kontorets verk knyter an till- och vidareutvecklar den svenska arkitekturtraditionen. Arbetet präglas av ett fenomenologiskt synsätt där rum, objekt och material gestaltas för att uppnå en atmosfär rik på sinnesupplevelser. Vi strävar alltid efter en omsorg kring de människor som ska bruka och uppleva de rum vi gestaltar genom goda kunskaper i hantverk, material, konstruktion och samhällsförståelse.`,
        `Resultatet är en rationell och sinnlig arkitektur som tar sina utgångspunkter i grundläggande humanistiska värden och platsens fysiska och kulturella kontext.`,
      ]}
      imagePosition="right"
      offset={2}
      columns={4}
      image={
        <StaticImage
          src="../images/arkitekterna.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Aron Wändahl Högberg och Benjamin Gillner"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
    <ImageTextBlock
      preHeading="Kökssnickeriet"
      text={[
        `Taras Hallgren & Son är ett familjeföretag med anor från tidigt 1900-tal. 1974 övertog sonen Nils-Bertil Hallgren företaget av sin far Taras. En ny snickeriverkstad uppfördes på Eckens väg i Partille där de än idag tillverkar all inredning. Idag är det tredje generationen som driver det miljödiplomerade familjeföretaget. Med 97-års erfarenhet av köks- och inredningstillverkning kan Taras Hallgren & Son erbjuda produkter som skapar en helhet och en känsla av tillfredställelse. Deras fördel är att de inte är beroende av standardmått och kan därmed skräddarsy en kökslösning efter just platsens behov med ett unikt resultat vad gäller form och funktion.`,
        `För Taras Hallgren & Son är hantverket viktigt! Den kunskap de har vill de förvalta och utveckla för att skapa inredning och möbler med högsta kvalité och klass då det är detaljer som gör inredningen, detaljer som många inte ser men som gör helheten.`,
      ]}
      imagePosition="left"
      offset={2}
      columns={5}
      image={
        <StaticImage
          src="../images/kokssnickeriet.jpg"
          quality={95}
          formats={["AUTO", "WEBP"]}
          alt="Gamla Träverktyg"
          placeholder="blurred"
          layout="fullWidth"
        />
      }
    />
  </Layout>
)

export default OmOss
